import React from 'react'
import { motion } from "framer-motion";
import UniversitiesMarquee from '../../components/UniversitiesMarquee/UniversitiesMarquee';

const Clients = () => {
    return (
        <div>
            {/* Hero Section */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className="bg-gradient-to-r from-green-400 via-blue-300 to-white-300 text-gray-900 py-24 px-6 mt-16"
            >
                <div className="max-w-4xl mx-auto text-center">
                    <motion.h1
                        className="text-5xl font-extrabold mb-6"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.7 }}
                    >
                        Our Valued Clients
                    </motion.h1>
                    <p className="text-lg max-w-3xl mx-auto leading-relaxed">
                        We take pride in collaborating with industry leaders and innovators. Here’s a showcase of our top clients who trust us to deliver exceptional results and drive success together.
                    </p>
                </div>
            </motion.div>
            <UniversitiesMarquee />
        </div>
    )
}

export default Clients
