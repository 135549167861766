import "./UniversitiesMarquee.css"
import Marquee from "react-fast-marquee";

const UniversitiesMarquee = () => {
    return (
        <section className="universities-section">
            <div className="universities-section-title">
                <span className="line"></span>
                <h2>Top Clients</h2>
                <span className="line"></span>
            </div>
            <Marquee className="universities-list" speed={30}>
                <img src="/clients_logo/1.webp" alt="" className="logo" />
                <img src="/clients_logo/2.webp" alt="" className="logo" />
                <img src="/clients_logo/3.webp" alt="" className="logo" />
                <img src="/clients_logo/4.webp" alt="" className="logo" />
                <img src="/clients_logo/5.webp" alt="" className="logo icon" />
                <img src="/clients_logo/6.webp" alt="" className="logo icon" />
                <img src="/clients_logo/7.webp" alt="" className="logo icon" />
            </Marquee>
            <Marquee className="universities-list" direction="right" speed={30}>
                <img src="/clients_logo/8.webp" alt="" className="logo" />
                <img src="/clients_logo/9.webp" alt="" className="logo" />
                <img src="/clients_logo/10.webp" alt="" className="logo" />
                <img src="/clients_logo/11.webp" alt="" className="logo" />
                <img src="/clients_logo/12.webp" alt="" className="logo icon" />
                <img src="/clients_logo/13.webp" alt="" className="logo icon" />
                <img src="/clients_logo/14.webp" alt="" className="logo icon" />
            </Marquee>
            <Marquee className="universities-list" speed={25}>
                <img src="/clients_logo/15.webp" alt="" className="logo" />
                <img src="/clients_logo/16.webp" alt="" className="logo" />
                <img src="/clients_logo/17.webp" alt="" className="logo" />
                <img src="/clients_logo/18.webp" alt="" className="logo" />
                <img src="/clients_logo/19.webp" alt="" className="logo icon" />
                <img src="/clients_logo/20.webp" alt="" className="logo icon" />
                <img src="/clients_logo/21.webp" alt="" className="logo icon" />
            </Marquee>
        </section>
    )
}

export default UniversitiesMarquee
